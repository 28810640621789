<template>
   <div class="col-md-12">
      <div id="container-web" :style="'width:'+ widthDisplayWebSite+';margin:0 auto;     justify-content: flex-end;'">
         <paper-layout id="wrap-menu"  :srcStyle="webSite" v-if="renderWeb "  :idioms="webSite.idioms" :language="webSite.language" :layout="webSite.layout" :models="webSite.binds" :palette="organizationPalette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
      </div>
   </div>
</template>
<script>
   //AWS - GRAPHQL
   import { smd_getWebPage, smd_getRecordIndex  } from "../../graphql/queries";
   import { onNotify, onUpdateSmartRecord } from '../../graphql/subscriptions'
   //Tools
   import Vuex from "vuex";
   import { Auth, API,Storage } from "aws-amplify";
   import "amazon-connect-streams";
   import draggable from "vuedraggable";
   import Swal from 'sweetalert2'
   import { loading, popUp, actionSidebar  } from "../../store/tools";

   //Components
   import PaperDisplayer from "../../components/papers/PaperDisplayer.vue";
   import PaperLayout from "../../components/papers/PaperLayout.vue";

   export default {
      components: {
         PaperDisplayer,
         PaperLayout
      },
      data() {
         return {
            //Record
            record: undefined,
            sml:'',
            webSite: undefined,
            renderWeb: false,
            widthDisplayWebSite:'100%'
         };
      },
      created() {
         console.log("test");
         loading('show', 'webPreview')
         this.setUserWeb()
      },
      methods: {
         ...Vuex.mapMutations([
            'setWebSite',
            'SetOrganizationID',
            'SetOrganizationData',
            'setPublicOrganization',
            'setPublicOrganizationData'
         ]),
         ...Vuex.mapActions([
            'getPublicOrganization'
         ]),
         //Init
         async setUserWeb(){
            Auth.signIn('smd_webuser', '383@JDU45ss#')
            .then((user) => { 
               //Clear Global Variables
               this.SetOrganizationID("Not authorized")
               this.SetOrganizationData("Not authorized")
               this.setPublicOrganization(undefined)
               this.setPublicOrganizationData(undefined)
               //console.clear()
               ///-----
               //this.getPublicOrganization()
               setTimeout(() => {
                  //this.getSML()
                  this.getLayout()
               }, 1500);
              //this.loading('hidden')
            })
            .catch((err) => console.log(err))
         },
         async getSML(){
            await this.getPublicOrganization()
            let pushData = {};
            let pullData = ''
            let sml = 'sales'
            let PK = '#SMARTDASH'
            try {
               if (this.$router.currentRoute.value.query.smlName) {
                  sml =  this.$router.currentRoute.value.query.smlName
               }
               if (this.$router.currentRoute.value.query.smlPK && this.$router.currentRoute.value.query.smlPK.toLowerCase() == 'org') {
                  PK =  this.publicOrganization
               }
               pushData = {
                  PK: PK+'##SML', 
                  SK: sml, 
                  index: '4'
               }
               console.log('pushData Get Layout:', pushData);
               pullData = await API.graphql({
                  query: smd_getRecordIndex,
                  variables: pushData
               });
               pullData = pullData.data.smd_getRecordIndex
               pullData = JSON.parse(pullData.data)
               this.getLayout(pullData)
            } catch (error) {
               popUp(error, 'cach', 'err')
               console.log(error);
            }
         }, 
         async getLayout(data){
            await this.getPublicOrganization()
            let SML= 'sales'
            if (this.$router.currentRoute.value.query.smlName) {
               SML = this.$router.currentRoute.value.query.smlName
            } 
            //console.clear()
            loading('show', 'webPreview')
            let pushData = {};
            let pullData = ''
            let sml = SML
            let PK = '#SMARTDASH'
            if (this.$router.currentRoute.value.query.smlName) {
               sml =  this.$router.currentRoute.value.query.smlName
            }
            if (this.$router.currentRoute.value.query.smlPK && this.$router.currentRoute.value.query.smlPK.toLowerCase() == 'org') {
               PK =  this.publicOrganization
            }
            try {
               pushData = {
                  SMLname: sml,
                  orgPK: this.publicOrganization,
                  SMLPK: PK,
                  //SMLSK: data.SK
               }
               console.log('pushData Get Layout:', pushData);
               pullData = await API.graphql({
                  query: smd_getWebPage,
                  variables: pushData
               });
               this.webSite = {}
               pullData = pullData.data.smd_getWebPage
               pullData = JSON.parse(pullData.data)
               for (let index = 0; index < JSON.parse(pullData.SML).length; index++) {
                  const element = JSON.parse(pullData.SML)[index];
                  try {
                     if (element.attributeType == 'L') {
                        element.attributeValue = JSON.parse(element.attributeValue)
                     }
                  } catch (error) {
                     console.log(error);
                  }
                  this.webSite[element.attributeName] = element.attributeValue
               }
               this.webSite.binds = pullData.binds
               // Reserved Words 
               this.webSite.binds.step = 'step1'
               this.webSite.binds.choice = 'null'
               this.webSite.binds.choiceValue = null
               //--------------
               this.webSite.binds.form = {}
               this.webSite.layout = pullData.layout
               this.webSite.palette = this.organizationPalette
               this.setWebSite(this.webSite)
               console.log('Web Site Data:', this.webSite);
               document.head.insertAdjacentHTML("beforeend", this.webSite.fontScript)
               const replacements = {
                  'var(--color0)': this.webSite.palette[0],
                  'var(--color1)': this.webSite.palette[1],
                  'var(--color2)': this.webSite.palette[2],
                  'var(--color3)': this.webSite.palette[3],
                  'var(--color4)': this.webSite.palette[4],
                  'var(--color5)': this.webSite.palette[5],
                  'var(--color6)': this.webSite.palette[6],
                  'var(--font0)': this.webSite.fonts[0],
                  'var(--font1)': this.webSite.fonts[1],
                  'var(--font2)': this.webSite.fonts[2],
                  'var(--font3)': this.webSite.fonts[3],
               };
               // Utilizar una expresión regular para buscar las palabras a reemplazar y la función replace() para hacer el reemplazo
               if (this.webSite.class) {
                  const replacedString = this.webSite.class.replace(/var\(--(color|font)\d\)/g, match => replacements[match]);
                  //console.log(replacedString);
                  document.head.insertAdjacentHTML("beforeend", '<style>'+replacedString+'</style>')
                  setTimeout(() => {
                     this.renderWeb = true
                  }, 100);
               }
               //console.clear()
               console.log(this.widthDisplayWebSite);
               if (this.widthDisplayWebSite == '450px') {
                  
                  // Definir la expresión regular para un bloque @media
                  const mediaRegex = /@media[^{]+\{([\s\S]+?})\s*}/g;
      
                  // Encontrar todas las coincidencias de bloques @media
                  let match;
                  while ((match = mediaRegex.exec(this.webSite.class)) !== null) {
                     // Extraer el contenido del bloque @media
                     const mediaBlockContent = match[1];
                     console.log(mediaBlockContent);
                     document.head.insertAdjacentHTML("beforeend", '<style>'+mediaBlockContent+'</style>')
                  }
               } 
               
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hide', 'webPreview')
         }
      },
      computed: {
         ...Vuex.mapState([
            "organizationID",
            "publicOrganization",
            "organizationPalette",
            "publicOrganizationData"
         ]),
      },
      watch: {
         
      },
   };

</script>